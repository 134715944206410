import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MCQQuestionResponse } from "../../apis/EvalAPI";
import "./MCQTest.css";

type PersonalityMCQProps = {
  data: MCQQuestionResponse;
  selected: number | null;
  setSelected: (arg1: number | null) => void;
  setSubmitDisabled: (disabled: boolean) => void;
};

function MCQTest(props: PersonalityMCQProps) {
  const handleOptionSelect = (
    _event: React.MouseEvent<HTMLElement>,
    newOption: number | null
  ) => {
    props.setSelected(newOption);
  };

  useEffect(() => {
    props.setSubmitDisabled(props.selected == null);
  }, [props.selected]);

  return (
    <div className="MCQTest">
      <div
        dangerouslySetInnerHTML={{
          __html: props.data.question,
        }}
      />
      {props.data.image_url &&
        props.data.image_url.map((image_url, index) => {
          return <img src={image_url} key={index} alt="NA" />;
        })}
      <ToggleButtonGroup
        value={props.selected}
        exclusive
        onChange={handleOptionSelect}
      >
        <div className="MCQTest-ToggleButtonGroup">
          {props.data.options.map((option, i) => (
            <ToggleButton key={i} value={i} color="primary">
              {option}
            </ToggleButton>
          ))}
        </div>
      </ToggleButtonGroup>
    </div>
  );
}

export default MCQTest;
