import { images } from '../../assets/index'
const data = {
  "modalData": [
    {
      "text": "360° Evaluation to map their technical & soft skills",
      "image": images.getStartedModal1
    },
    {
      "text": "AI-powered skilling programs to make students industry ready",
      "image": images.getStartedModal2
    },
    {
      "text": "Placement Support & resources to kickstart their career journey",
      "image": images.getStartedModal3
    }
  ],
  "domains": [
    {
      "text": "Programming & Software Development",
      "image": images.coding,
      "bgColor": "#E7EDFF"
    },
    {
      "text": "Data Science & Analytics",
      "image": images.data,
      "bgColor": "#FFEFE3"
    },
    {
      "text": "UI/UX Design",
      "image": images.uiux,
      "bgColor": "#FFE7EC"
    },
    {
      "text": "Business Communication",
      "image": images.business,
      "bgColor": "#FEF5D8"
    },
    {
      "text": "Program Management",
      "image": images.program,
      "bgColor": "#E9F8F2"
    },
    {
      "text": "Sales & Digital Marketing",
      "image": images.marketing,
      "bgColor": "#E7EDFF"
    },
    {
      "text": "Accounting & Finance",
      "image": images.accounts,
      "bgColor": "#F1E9FB"
    }
  ],
  "testimonials": [
    {
      "name": "Yasir1",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    },
    {
      "name": "Yasir2",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    },
    {
      "name": "Yasir3",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    },
    {
      "name": "Yasir4",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    },
    {
      "name": "Yasir5",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    },
    {
      "name": "Yasir6",
      "rating": 4,
      "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam nihil suscipit quidem cumque fuga corrupti? Sed excepturi quia nihil qui odit ipsum cupiditate libero eveniet?",
      "about": "Student",
      "image": images.yasir
    }
  ],
  "stepsCards": [
    {
      count: "01",
      title: "INTEREST MAP",
      desc: "Map your unique preferences, interests and profile to determine the best career opportunities!",
      image: images.interestMap,
      position: "left"
    },
    {
      count: "02",
      title: "EVALUATION",
      desc: "Evaluate your skills for specific job roles using our AI-powered assessments!",
      image: images.evaluation,
      position: "right"
    },
    {
      count: "03",
      title: "UP-SKILLING",
      desc: "Use our AI Instructor Disha to get curated skill programs & certifications from top institutions!",
      image: images.upSkilling,
      position: "left"
    },
    {
      count: "04",
      title: "PLACMENTS",
      desc: "Build your AI-powered Profile, take Mock interviews & Mentorship to unlock top roles!",
      image: images.placement,
      position: "right"
    },
    {
      count: "05",
      title: "COMMUNITY",
      desc: "Track your career journey, compare with peers and be part of our thriving Sakshm Community!",
      image: images.community,
      position: "left"
    },
  ],
  "newsImages": [
    {
      image: images.newsBg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, perspiciatis!"
    },
    {
      image: images.newsBg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, perspiciatis!"
    },
    {
      image: images.newsBg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, perspiciatis!"
    },
    {
      image: images.newsBg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, perspiciatis!"
    },
    {
      image: images.newsBg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, perspiciatis!"
    },
  ]
}


export default data