const PERSONALITY = {
  INTJ: {
    personality:
      'INTJs, often known as "Architects," are strategic thinkers who excel in creating detailed plans to achieve their goals. They are independent, analytical, and highly capable of seeing the big picture. INTJs value knowledge, competence, and logic, often setting high standards for themselves and others. They prefer working alone or leading teams with clear objectives, focusing on long-term outcomes. Their creativity and determination make them excellent problem solvers, especially in complex and challenging situations. INTJs are confident and self-assured, always striving for improvement and efficiency. ',
    strength_and_weakness:
      "INTJs are rational, informed, independent, and determined. They have a natural ability to understand complex concepts and develop innovative solutions. However, their weaknesses can include arrogance, being overly analytical, and having difficulty expressing emotions. They may come across as insensitive or overly critical, struggling with small talk and preferring deep, meaningful conversations. INTJs often have high expectations and can be frustrated when others do not meet their standards. They value logic over emotions, which can sometimes lead to misunderstandings in personal relationships. ",
    career_path:
      "INTJs excel in careers that require strategic planning and independent thinking. They are well-suited for roles such as scientists, engineers, professors, and lawyers. Their ability to think critically and solve complex problems makes them valuable in research and development fields. INTJs also thrive in leadership positions where they can implement their innovative ideas and drive projects to success. Careers in academia, strategic planning, and technology often appeal to INTJs, providing intellectual challenges and opportunities for growth. ",
  },
  INTP: {
    personality:
      'INTPs, known as "Logicians," are innovative and intellectual individuals with a deep-seated curiosity about the world. They are driven by a desire to understand complex systems and uncover underlying principles. INTPs are highly analytical and excel in abstract thinking and problem-solving. They prefer working independently or in small, intellectually stimulating teams. INTPs value logic and objectivity, often losing themselves in thought and exploration of new ideas. They are introspective and thoughtful, with a strong inclination towards theoretical and conceptual work. ',
    strength_and_weakness:
      "INTPs are analytical, original, open-minded, and objective. They are adept at breaking down complex problems and developing creative solutions. However, their weaknesses include being absent-minded, condescending, and resistant to routine and rules. INTPs can become so absorbed in their thoughts that they overlook practical details. Their preference for intellectual pursuits can make them appear aloof or detached from others. Social interactions may be challenging, and they often prefer spending time alone or with a few close friends. ",
    career_path:
      "INTPs thrive in careers that allow them to utilize their analytical and creative skills. They excel as researchers, scientists, freelance consultants, and architects. These roles provide the intellectual stimulation and independence INTPs crave. Careers in academia, technology, engineering, and theoretical research are particularly appealing to INTPs, as they allow them to engage in problem-solving and explore new ideas. INTPs also enjoy fields that encourage innovation and intellectual exploration, where they can continuously learn and grow. ",
  },
  ENTJ: {
    personality:
      'ENTJs, often referred to as "Commanders," are natural leaders with a talent for strategic thinking and decision-making. They are ambitious, confident, and driven by a desire to achieve and succeed. ENTJs excel in organizing and directing teams to accomplish common goals. Their ability to see the big picture and develop effective plans makes them highly effective leaders. ENTJs enjoy competition and are always looking for ways to improve and optimize processes. They value efficiency, effectiveness, and are often seen as assertive and determined. ',
    strength_and_weakness:
      "ENTJs are efficient, energetic, self-confident, and strong-willed. They are strategic thinkers who can quickly identify goals and develop plans to achieve them. However, their weaknesses can include being stubborn, intolerant, and impatient. ENTJs can sometimes come across as domineering or insensitive to others' needs. Their high standards and relentless pursuit of success can lead to conflicts with those who do not share their level of ambition. They may also struggle with expressing vulnerability and can be seen as overly critical. ",
    career_path:
      "ENTJs thrive in careers that require leadership and strategic thinking. They are well-suited for roles such as executives, entrepreneurs, lawyers, and judges. Their ability to make tough decisions and inspire others makes them valuable in management and leadership positions. ENTJs also excel in competitive environments where they can utilize their strategic thinking and drive to succeed. They prefer careers that offer opportunities for growth and advancement. Positions in business, law, and politics often appeal to ENTJs, as these fields allow them to use their strengths and pursue their ambitious goals. ",
  },
  ENTP: {
    personality:
      'ENTPs, often known as "Debaters," are smart, curious, and energetic individuals who thrive on intellectual challenges. They enjoy exploring new ideas, questioning the status quo, and engaging in spirited debates. ENTPs are highly innovative and creative, often coming up with original solutions to complex problems. They are charismatic and enjoy engaging with others in thought-provoking discussions. ENTPs have a quick wit and are adept at seeing multiple perspectives, making them excellent problem solvers. They value knowledge and are always seeking to expand their understanding. ',
    strength_and_weakness:
      "ENTPs are knowledgeable, quick thinkers, original, and excellent brainstormers. They are adept at coming up with creative solutions and exploring new ideas. However, their weaknesses include being very argumentative, intolerant, and disliking practical matters. ENTPs can sometimes be overly focused on abstract ideas and neglect practical details. Their love of debate can lead to conflicts with others who may find their constant questioning exhausting. They can also be seen as inconsistent, as they may quickly lose interest in projects once the initial excitement wears off. ",
    career_path:
      "ENTPs excel in careers that allow them to explore new ideas and engage in intellectual challenges. They are well-suited for roles such as entrepreneurs, inventors, marketers, and consultants. These careers provide the flexibility and creativity ENTPs need to thrive. They are also effective in roles that involve public speaking and debate, such as lawyers and politicians. ENTPs thrive in dynamic environments where they can use their quick thinking and problem-solving skills. They enjoy careers that offer variety and opportunities to innovate, constantly seeking new challenges to keep them engaged. ",
  },
  INFJ: {
    personality:
      'INFJs, often called "Advocates," are quiet, insightful, and deeply caring individuals. They are driven by a desire to make a positive impact on the world and help others. INFJs are highly intuitive and empathetic, often sensing the emotions and needs of those around them. They value deep, meaningful connections and strive for authenticity in their relationships. INFJs are idealistic and principled, guided by a strong sense of purpose and a commitment to their values. They are creative and visionary, often seeking to inspire and uplift others. ',
    strength_and_weakness:
      "INFJs are creative, insightful, principled, and passionate. They are excellent at understanding and supporting others, often serving as a source of inspiration and guidance. However, their weaknesses include being sensitive, extremely private, and perfectionistic. INFJs can become overwhelmed by their own emotions and the emotions of others, leading to burnout. They may struggle with setting boundaries and can be easily hurt by criticism or conflict. Their idealism can also lead to frustration when reality falls short of their expectations. ",
    career_path:
      "INFJs thrive in careers that allow them to help others and make a positive impact. They are well-suited for roles such as counselors, psychologists, writers, and teachers. These careers provide the opportunity for INFJs to use their empathy and intuition to support and inspire others. They also excel in creative fields where they can express their ideals and vision. INFJs are motivated by a deep sense of purpose and seek careers that align with their values. They enjoy work that allows them to connect with others on a meaningful level and contribute to the greater good. ",
  },
  INFP: {
    personality:
      'INFPs, known as "Mediators," are poetic, kind, and altruistic individuals who are deeply committed to their values. They are idealistic and driven by a desire to make the world a better place. INFPs are highly empathetic and often put others\' needs before their own. They enjoy exploring their creativity and often express themselves through writing, art, or other creative pursuits. INFPs value authenticity and seek deep, meaningful connections in their relationships. They are introspective and thoughtful, often reflecting on their experiences and emotions. ',
    strength_and_weakness:
      "INFPs are empathetic, generous, open-minded, and creative. They excel at understanding and supporting others, often serving as compassionate and caring friends and partners. However, their weaknesses include being unfocused, overly idealistic, and impractical. INFPs can struggle with staying grounded and may have difficulty with routine tasks and practical matters. They can also become easily overwhelmed by their emotions and the emotions of others. Their idealism can lead to disappointment when reality does not align with their values and expectations. ",
    career_path:
      "INFPs excel in careers that allow them to help others and express their creativity. They are well-suited for roles such as writers, psychologists, counselors, and teachers. These careers provide the opportunity for INFPs to use their empathy and creativity to support and inspire others. They also thrive in environments that value authenticity and allow for self-expression. INFPs seek careers that align with their values and provide a sense of purpose. They enjoy work that allows them to connect with others on a meaningful level and contribute to the greater good. Fields such as the arts, education, and social services are particularly appealing to INFPs. ",
  },

  ENFJ: {
    personality:
      'ENFJs, often known as "Protagonists," are charismatic, inspiring, and natural-born leaders. They are deeply empathetic and have a strong desire to help others reach their full potential. ENFJs are highly sociable and excel in environments that require collaboration and communication. They are driven by a strong sense of purpose and are often motivated by their ideals and values. ENFJs are persuasive and can inspire and motivate those around them. They are passionate about their beliefs and are often involved in activities that promote social justice and community building. ',
    strength_and_weakness:
      "ENFJs are tolerant, reliable, charismatic, and altruistic. They are excellent communicators and can connect with people on a deep emotional level. Their enthusiasm and passion are contagious, making them effective leaders and motivators. However, their weaknesses include being overly idealistic, too selfless, and overly sensitive. ENFJs can struggle with setting boundaries and may take on too much responsibility, leading to burnout. They can also be overly concerned with others' opinions and may have difficulty handling criticism. Their desire to help others can sometimes lead to neglecting their own needs. ",
    career_path:
      "ENFJs thrive in careers that allow them to lead and support others. They are well-suited for roles such as politicians, teachers, coaches, and social workers. These careers provide the opportunity for ENFJs to use their empathy and leadership skills to make a positive impact. They also excel in roles that involve public speaking and communication. ENFJs seek careers that align with their values and provide a sense of purpose. They enjoy work that allows them to connect with others on a meaningful level and contribute to the greater good. Fields such as education, counseling, and community organizing are particularly appealing to ENFJs. ",
  },
  ENFP: {
    personality:
      'ENFPs, often known as "Campaigners," are enthusiastic, creative, and sociable individuals. They are free-spirited and enjoy exploring new ideas and possibilities. ENFPs are highly empathetic and value deep and meaningful connections. They are energetic and enjoy engaging with others, often bringing a sense of joy and positivity to their interactions. ENFPs are motivated by their ideals and strive to make a positive impact on the world. They are adaptable and open-minded, always seeking new experiences and opportunities for growth. ',
    strength_and_weakness:
      "ENFPs are curious, observant, energetic, and enthusiastic. They are excellent at exploring new ideas and connecting with others. Their creativity and optimism are infectious, making them inspiring and motivating to be around. However, their weaknesses include having poor practical skills, overthinking things, and getting stressed easily. ENFPs can struggle with staying focused and may become overwhelmed by their emotions. They can also be easily distracted by new ideas and possibilities, leading to unfinished projects. Their idealism can sometimes lead to unrealistic expectations. ",
    career_path:
      "ENFPs excel in careers that allow them to explore new ideas and connect with others. They are well-suited for roles such as actors, musicians, artists, and psychologists. These careers provide the flexibility and creativity ENFPs need to thrive. They also enjoy roles that involve public speaking, writing, and marketing. ENFPs seek careers that offer variety and opportunities for innovation, constantly looking for new challenges to keep them engaged. Fields such as the arts, media, and communications are particularly appealing to ENFPs, as they allow them to express their creativity and connect with others on a meaningful level. They also excel in entrepreneurial ventures, where they can bring their visionary ideas to life. ",
  },
  ISTJ: {
    personality:
      'ISTJs, known as "Logisticians," are practical and fact-minded individuals who value reliability and order. They are known for their strong sense of duty and dedication to their work and commitments. ISTJs are highly organized and prefer to work within structured environments. They are detail-oriented and methodical, ensuring that tasks are completed efficiently and accurately. ISTJs value tradition and stability, often adhering to established procedures and protocols. They are logical and responsible, making them trustworthy and dependable in both personal and professional settings. ',
    strength_and_weakness:
      "ISTJs are honest, direct, responsible, and practical. They excel in environments that require attention to detail and adherence to rules. Their methodical approach ensures tasks are completed accurately and efficiently. However, their weaknesses can include being stubborn, insensitive, and always by the book. ISTJs can sometimes struggle with adapting to new or unconventional methods. They may come across as rigid or inflexible, and their focus on practicality can sometimes lead them to overlook the emotional aspects of situations. ISTJs may also struggle with expressing their feelings and can be perceived as reserved or distant. ",
    career_path:
      "ISTJs thrive in careers that require precision, organization, and reliability. They are well-suited for roles such as auditors, accountants, lawyers, and detectives. These careers allow them to utilize their attention to detail and methodical approach. ISTJs also excel in fields that value tradition and stability, such as the military, law enforcement, and government services. They prefer careers that offer clear expectations and structured environments. Positions in project management, engineering, and logistics are particularly appealing to ISTJs, as they provide opportunities to use their strengths and contribute to efficient and effective operations. ",
  },
  ISFJ: {
    personality:
      'ISFJs, known as "Defenders," are warm, dedicated, and reliable individuals who are always ready to protect and support their loved ones. They are known for their strong sense of duty and commitment to helping others. ISFJs are highly empathetic and attentive to the needs of those around them. They value harmony and strive to create a supportive and nurturing environment. ISFJs are practical and detail-oriented, often excelling in roles that require meticulous care and attention. They are loyal and dependable, making them trustworthy and reliable friends and colleagues. ',
    strength_and_weakness:
      "ISFJs are supportive, reliable, observant, and enthusiastic. They excel at creating a sense of stability and harmony in their environments. Their empathy and attention to detail make them excellent caregivers and supporters. However, their weaknesses can include being overly humble, reluctant to change, and too altruistic. ISFJs can sometimes struggle with setting boundaries and may take on too much responsibility, leading to burnout. They can also be resistant to new or unconventional ideas, preferring to stick with familiar methods. ISFJs may also have difficulty asserting themselves and can be taken advantage of by others. ",
    career_path:
      "ISFJs thrive in careers that allow them to care for and support others. They are well-suited for roles such as nurses, administrators, counselors, and teachers. These careers provide the opportunity for ISFJs to use their empathy and attention to detail to help others. ISFJs also excel in roles that require meticulous care and precision, such as medical technicians, librarians, and archivists. They prefer careers that offer stability and clear expectations. Fields such as healthcare, education, and social services are particularly appealing to ISFJs, as they allow them to create positive and supportive environments for those in need. ",
  },
  ESTJ: {
    personality:
      'ESTJs, often referred to as "Executives," are known for their strong leadership skills and practical approach to problem-solving. They are highly organized, efficient, and dedicated to their work and responsibilities. ESTJs value tradition and order, often excelling in roles that require structure and clear guidelines. They are natural leaders who are confident in making decisions and delegating tasks. ESTJs are logical and detail-oriented, ensuring that projects are completed accurately and on time. They are reliable and dependable, often seen as pillars of strength in their communities and workplaces. ',
    strength_and_weakness:
      "ESTJs are dedicated, strong-willed, direct, and honest. They excel in environments that require organization and efficiency. Their leadership skills and practical approach make them effective managers and administrators. However, their weaknesses can include being inflexible, stubborn, and overly critical. ESTJs can sometimes struggle with adapting to new or unconventional methods. They may come across as rigid or insensitive, focusing more on efficiency and results rather than the emotional aspects of situations. ESTJs may also have difficulty delegating tasks, preferring to take on responsibilities themselves to ensure they are done correctly. ",
    career_path:
      "ESTJs thrive in careers that require leadership, organization, and efficiency. They are well-suited for roles such as military officers, judges, lawyers, and managers. These careers allow them to utilize their strong leadership skills and practical approach to problem-solving. ESTJs also excel in fields that value tradition and structure, such as government services, law enforcement, and corporate management. They prefer careers that offer clear expectations and opportunities for advancement. Positions in operations management, logistics, and project management are particularly appealing to ESTJs, as they provide opportunities to use their strengths and contribute to efficient and effective operations. ",
  },
  ESFJ: {
    personality:
      'ESFJs, known as "Consuls," are warm, social, and highly caring individuals who are always eager to help others. They are known for their strong sense of duty and commitment to their communities and loved ones. ESFJs are highly empathetic and attentive to the needs of those around them. They value harmony and strive to create a supportive and nurturing environment. ESFJs are practical and organized, often excelling in roles that require meticulous care and attention. They are loyal and dependable, making them trustworthy and reliable friends and colleagues. ',
    strength_and_weakness:
      "ESFJs are supportive, reliable, observant, and enthusiastic. They excel at creating a sense of stability and harmony in their environments. Their empathy and attention to detail make them excellent caregivers and supporters. However, their weaknesses can include being overly humble, reluctant to change, and too altruistic. ESFJs can sometimes struggle with setting boundaries and may take on too much responsibility, leading to burnout. They can also be resistant to new or unconventional ideas, preferring to stick with familiar methods. ESFJs may also have difficulty asserting themselves and can be taken advantage of by others. ",
    career_path:
      "ESFJs thrive in careers that allow them to care for and support others. They are well-suited for roles such as social workers, nurses, teachers, and administrators. These careers provide the opportunity for ESFJs to use their empathy and organizational skills to help others. ESFJs also excel in roles that require meticulous care and precision, such as event planning, hospitality, and customer service. They prefer careers that offer stability and clear expectations. Fields such as healthcare, education, and social services are particularly appealing to ESFJs, as they allow them to create positive and supportive environments for those in need. They also thrive in community-focused roles where they can directly contribute to the well-being and happiness of others. ",
  },
  ISTP: {
    personality:
      'ISTPs, known as "Virtuosos," are bold and practical individuals who are masters of all kinds of tools. They are curious and analytical, always eager to explore and understand how things work. ISTPs enjoy hands-on activities and are often drawn to fields that require technical skills and problem-solving abilities. They value independence and prefer working in environments that allow them the freedom to innovate and experiment. ISTPs are adaptable and resourceful, often thriving in dynamic and unpredictable situations. They are known for their ability to stay calm under pressure and find practical solutions to immediate problems. ',
    strength_and_weakness:
      "ISTPs are optimistic, energetic, creative, and practical. They excel at solving problems in the moment and are highly skilled in using tools and technology. However, their weaknesses can include being stubborn, insensitive, and risk-prone. ISTPs can sometimes struggle with long-term planning and may be seen as unpredictable or unreliable due to their spontaneous nature. They may also have difficulty with emotional expression and can be perceived as distant or aloof. ISTPs prefer action over discussion and may become frustrated with bureaucratic processes and rigid rules. ",
    career_path:
      "ISTPs thrive in careers that allow them to use their technical skills and problem-solving abilities. They are well-suited for roles such as mechanics, engineers, designers, and pilots. These careers provide the hands-on activities and independence ISTPs crave. They also excel in fields that require quick thinking and adaptability, such as emergency services, construction, and IT support. ISTPs prefer careers that offer variety and the opportunity to work with their hands. Positions in technology, manufacturing, and skilled trades are particularly appealing to ISTPs, as they provide opportunities to innovate and solve practical problems. ",
  },
  ISFP: {
    personality:
      'ISFPs, known as "Adventurers," are flexible and charming individuals who are always ready to explore and experience something new. They are sensitive and artistic, often expressing themselves through creative pursuits such as music, art, and crafting. ISFPs value freedom and autonomy, preferring to live in the moment rather than adhering to rigid plans or schedules. They are empathetic and caring, often attuned to the emotions of those around them. ISFPs are introverted but enjoy connecting with others on a deep and personal level. They are open-minded and accepting, valuing individuality and personal expression. ',
    strength_and_weakness:
      "ISFPs are charming, sensitive to others, imaginative, and passionate. They excel at creating and appreciating beauty and are often drawn to artistic and creative endeavors. However, their weaknesses can include being fiercely independent, unpredictable, and easily stressed. ISFPs can struggle with planning for the future and may have difficulty with routine tasks and responsibilities. They may also be prone to avoiding conflict and can be seen as passive or indecisive. ISFPs value personal freedom and can become restless or dissatisfied in highly structured or restrictive environments. ",
    career_path:
      "ISFPs thrive in careers that allow them to express their creativity and individuality. They are well-suited for roles such as artists, musicians, chefs, and photographers. These careers provide the freedom and opportunity for personal expression that ISFPs crave. They also excel in fields that involve helping others, such as healthcare, social work, and counseling. ISFPs prefer careers that offer flexibility and the chance to work independently or in small, supportive teams. Fields such as the arts, design, and hospitality are particularly appealing to ISFPs, as they allow them to combine their creativity with their desire to make a positive impact on others' lives. ",
  },

  ESTP: {
    personality:
      'ESTPs, known as "Entrepreneurs," are energetic, outgoing, and action-oriented individuals who enjoy living on the edge. They are known for their boldness and willingness to take risks, often thriving in fast-paced and dynamic environments. ESTPs are highly perceptive and can quickly assess situations and make decisions. They are practical and resourceful, often finding innovative solutions to immediate problems. ESTPs are social and charismatic, enjoying interactions with a wide range of people. They are natural leaders who can inspire and motivate others with their enthusiasm and confidence. ',
    strength_and_weakness:
      "ESTPs are bold, rational, practical, and direct. They excel at making quick decisions and taking action in the moment. Their confidence and charisma make them effective leaders and motivators. However, their weaknesses can include being impatient, risk-prone, and unstructured. ESTPs can struggle with long-term planning and may become easily bored with routine tasks. They may also have difficulty with authority and rules, preferring to operate independently. ESTPs' focus on immediate results can sometimes lead to overlooking potential long-term consequences. ",
    career_path:
      "ESTPs thrive in careers that offer excitement, variety, and the opportunity to take action. They are well-suited for roles such as entrepreneurs, sales representatives, marketers, and police officers. These careers provide the fast-paced and dynamic environments ESTPs crave. They also excel in fields that require quick thinking and adaptability, such as emergency services, sports, and entertainment. ESTPs prefer careers that offer opportunities for growth and allow them to work independently or lead teams. Positions in business, finance, and management are particularly appealing to ESTPs, as they provide the chance to use their strengths and achieve tangible results. ",
  },
  ESFP: {
    personality:
      'ESFPs, known as "Entertainers," are spontaneous, energetic, and enthusiastic individuals who bring joy and excitement to those around them. They are outgoing and social, enjoying interactions with a wide range of people. ESFPs are highly observant and in tune with their surroundings, often thriving in environments that are lively and dynamic. They value freedom and autonomy, preferring to live in the moment and make the most of every experience. ESFPs are empathetic and caring, often going out of their way to support and uplift others. They are practical and resourceful, finding creative solutions to everyday problems. ',
    strength_and_weakness:
      "ESFPs are bold, original, practical, and observant. They excel at bringing energy and enthusiasm to their environments and are often the life of the party. Their empathy and attentiveness make them excellent caregivers and supporters. However, their weaknesses can include being easily bored, poor long-term planners, and unfocused. ESFPs can struggle with routine tasks and responsibilities, preferring to focus on immediate and enjoyable experiences. They may also have difficulty with authority and rules, valuing their freedom and independence. ESFPs' focus on the present can sometimes lead to neglecting future planning and preparation. ",
    career_path:
      "ESFPs thrive in careers that offer excitement, variety, and the opportunity to interact with others. They are well-suited for roles such as actors, musicians, event planners, and marketers. These careers provide the dynamic and social environments ESFPs crave. They also excel in fields that involve helping others, such as healthcare, education, and hospitality. ESFPs prefer careers that offer flexibility and allow them to use their creativity and practical skills. Fields such as entertainment, media, and customer service are particularly appealing to ESFPs, as they provide opportunities to connect with others and make a positive impact. They also enjoy entrepreneurial ventures, where they can bring their innovative ideas to life and create vibrant, engaging experiences for others. ",
  },
};

export default PERSONALITY;
