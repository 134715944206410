import { createChatBotMessage } from "react-chatbot-kit";
import { auth } from "./firebase";
import { icons, images } from "../assets";

const getProfileImage = () => {
  let profileImg = auth.currentUser?.photoURL;
  if (profileImg) {
    return profileImg;
  } else return "";
};

const config = {
  initialMessages: [createChatBotMessage(`Hello world`)],
  customComponents: {
    botAvatar: (props) => (
      <img
        className="react-chatbot-kit-chat-bot-avatar-container"
        src={icons.bot}
        alt=""
      />
    ),
    userAvatar: (props) => (
      <img
        referrerpolicy="no-referrer"
        className="react-chatbot-kit-user-avatar-container"
        src={images.student}
        alt=""
      />
    ),
  },
};

export default config;
