// src/assets/index.ts

import { link } from "fs";
import bookSaved from "./emojis/book-saved.svg";
import briefcase from "./emojis/briefcase.svg";
import chart from "./emojis/chart.svg";
import edit from "./emojis/edit.svg";
import happy from "./emojis/happy.svg";
import likeDislike from "./emojis/like-dislike.svg";
import listen from "./emojis/math.svg";
import math from "./emojis/math.svg";
import messages from "./emojis/messages.svg";
import shapes from "./emojis/shapes.svg";
import userSquare from "./emojis/user-square.svg";

import bot from "./icons/bot.jpg";
import customerService from "./icons/customer-service.svg";
import deleteIcon from "./icons/delete.png";
import google from "./icons/google.svg";
import headerLogo from "./icons/header-logo.svg";
import history from "./icons/history.svg";
import home from "./icons/home.svg";
import linkedin from "./icons/linkedin.svg";
import logOut from "./icons/log-out.svg";
import mic from "./icons/mic.svg";
import profile1 from "./icons/profile-1.svg";
import profile from "./icons/profile.svg";
import report from "./icons/report.svg";
import trash from "./icons/trash.svg";
import googleSvg from "./icons/google.svg";
import resume from "./icons/resume.svg";
import code from "./icons/code.svg";
import documentCode from "./icons/document-code.svg";
import activity from "./icons/activity.svg";
import mobileProgramming from "./icons/mobile-programming.svg";
import messageEdit from "./icons/message-edit.svg";
import clipboardTick from "./icons/clipboard-tick.svg";
import hourGlass from "./icons/hour-glass.svg";
import messagesHelp from "./icons/messages.svg";

import communicationSkills from "./images/communication-skills.svg";
import evalHomePlaceholder from "./images/eval-home-placeholder.svg";
import evalSubmitted from "./images/eval-submitted.svg";
import homeImage from "./images/home.svg";
import hourglass from "./images/hourglass.svg";
import industryReady from "./images/industry-ready.svg";
import landing from "./images/landing.svg";
import placementSupport from "./images/placement-support.svg";
import psychometricAssessment from "./images/psychometric-assessment.svg";
import quantitativeAbility from "./images/quantitative-ability.svg";
import reportImage from "./images/report.svg";
import resultLeft from "./images/result-left.svg";
import resultMiddle from "./images/result-middle.svg";
import resultRight from "./images/result-right.svg";
import testConfirm from "./images/test-confirm.svg";
import testSkills from "./images/test-skills.svg";
import logoNew from "./images/logo-clarified.png";
import sakshamLogo from "./images/Sakshm.ai.svg";
import backgroundLanding from "./images/background.svg";
import landingHeroRight from "./images/landingHeroSvgRightSide.svg";
import featureCard1 from "./images/featureCard1.svg";
import featureCard2 from "./images/featureCard2.svg";
import featureCard3 from "./images/featureCard3.svg";
import featureCard4 from "./images/featureCard4.svg";
import apurv from "./images/apurv.svg";
import kashish from "./images/kashish.svg";
import founderSectionIllustration1 from "./images/founderSection1.svg";
import founderSectionIllustration2 from "./images/founderSection2.svg";
import founderSectionIllustration3 from "./images/founderSection3.svg";
import doubleQuote from "./images/doubleQuote.svg";
import partners from "./images/partners.svg";
import getStartedModal1 from "./images/getStartedModal1.svg";
import getStartedModal2 from "./images/getStartedModal2.svg";
import getStartedModal3 from "./images/getStartedModal3.svg";
import thankYou from "./images/thankYou.gif";
import coding from "./images/coding.svg";
import data from "./images/data.svg";
import uiux from "./images/uiux.svg";
import business from "./images/business.svg";
import program from "./images/program.svg";
import accounts from "./images/accounts.svg";
import marketing from "./images/marketing.svg";
import yasir from "./images/pic.jpg";
import newsBg from "./images/newsBg.svg";
import login from "./images/login.svg";
import wrongLoginCred from "./images/wrongLoginCred.svg";
import thanksGettingStarted from "./images/thanksGettingStarted.svg";
import interestMap from "./images/interestMap.svg";
import evaluation from "./images/Evaluation.svg";
import upSkilling from "./images/upSkilling.svg";
import placement from "./images/Placements.svg";
import community from "./images/community.svg";
import avatar from "./images/avatar.png";
import curvedLine from "./images/curvedLine.svg";
import student from "./images/student.jpg";

export const emojis: { [key: string]: string } = {
  edit,
  math,
  listen,
  "book-saved": bookSaved,
  briefcase,
  chart,
  happy,
  "like-dislike": likeDislike,
  messages,
  shapes,
  "user-square": userSquare,
};

export const icons = {
  bot,
  customerService,
  deleteIcon,
  google,
  headerLogo,
  history,
  home,
  linkedin,
  logOut,
  mic,
  profile1,
  profile,
  report,
  trash,
  googleSvg,
  resume,
  code,
  documentCode,
  activity,
  mobileProgramming,
  messageEdit,
  clipboardTick,
  hourGlass,
  messagesHelp,
};

export const images = {
  communicationSkills,
  evalHomePlaceholder,
  evalSubmitted,
  homeImage,
  hourglass,
  industryReady,
  landing,
  placementSupport,
  psychometricAssessment,
  quantitativeAbility,
  reportImage,
  resultLeft,
  resultMiddle,
  resultRight,
  testConfirm,
  testSkills,
  logoNew,
  sakshamLogo,
  backgroundLanding,
  landingHeroRight,
  featureCard1,
  featureCard2,
  featureCard3,
  featureCard4,
  apurv,
  kashish,
  founderSectionIllustration1,
  founderSectionIllustration2,
  founderSectionIllustration3,
  partners,
  doubleQuote,
  getStartedModal1,
  getStartedModal2,
  getStartedModal3,
  thankYou,
  coding,
  data,
  uiux,
  business,
  program,
  accounts,
  marketing,
  yasir,
  newsBg,
  login,
  wrongLoginCred,
  thanksGettingStarted,
  interestMap,
  evaluation,
  upSkilling,
  placement,
  community,
  avatar,
  curvedLine,
  student,
};
