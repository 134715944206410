import { useState, useEffect } from "react";
import { auth } from "./../configs/firebase";

interface UserData {
  name: string;
  email: string;
}

const useUserData = (): UserData => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user?.displayName) {
        setName(user.displayName);
      }
      if (user?.email) {
        setEmail(user.email);
      }
    });
    return unsubscribe;
  }, []);

  return { name, email };
};

export default useUserData;
