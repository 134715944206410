import { TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import "./Login.css";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../configs/firebase";

interface IFormInputs {
  email: string;
}

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>();

  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);

  const onPasswordResetInit: SubmitHandler<IFormInputs> = async (data) => {
    console.log("clicked");
    try {
      await sendPasswordResetEmail(auth, data.email);
      setPasswordResetEmailSent(true);
    } catch (error: any) {
      if (error.code === "auth/user-not-found") {
        setError("email", {
          type: "validate",
          message: "User with the given email not found",
        });
      }
    }
  };

  return (
    <div className="ResetPassword">
      <form
        onSubmit={handleSubmit(onPasswordResetInit)}
        className="reset-password-container"
      >
        <TextField
          style={{
            width: "100%",
          }}
          label="Email"
          {...register("email", {
            required: "Please enter the email",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Please enter a valid email",
            },
          })}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message?.toString() : ""}
        />
        {passwordResetEmailSent && (
          <div>
            If a user exists with this email then the password reset email has
            been sent.
          </div>
        )}
        <button
          className={
            "email-pass-btn" +
            (passwordResetEmailSent ? " button-yellow-secondary" : "")
          }
          disabled={passwordResetEmailSent}
        >
          Send reset email
        </button>
      </form>
    </div>
  );
}
