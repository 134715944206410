import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, googleProvider } from "../../configs/firebase";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import Header from "../../components/Header/Header";
import { icons } from "../../assets";
import { Box, Tab, Tabs, TextField } from "@mui/material";
import ResetPassword from "./ResetPassword";

interface IFormInputs {
  emailRegister: string;
  passwordRegister: string;
  emailLogin: string;
  passwordLogin: string;
}

export function LoginContentHeader() {
  return (
    <div className="login-header">
      <div className="login-header-text-container">
        <h1>Your future begins NOW!</h1>
        <div className="login-header-text">
          Evaluate, upskill and get placed in top companies and startups
        </div>
      </div>
    </div>
  );
}

enum EmailPassLoginType {
  REGISTER,
  LOGIN,
}

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>();

  const [emailPassLoginType, setEmailPassLoginType] = useState(0);

  useEffect(() => {
    if (auth.currentUser != null) {
      navigate("/home");
    }
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/home");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // useEffect(() => {
  //   if (isSignInWithEmailLink(auth, window.location.href)) {
  //     const email = window.localStorage.getItem("emailForSignIn");
  //     if (email) {
  //       signInWithEmailLink(auth, email, window.location.href)
  //         .then(() => {
  //           window.localStorage.removeItem("emailForSignIn");
  //         })
  //         .catch((error) => {
  //           console.error(`Couldn't sign in with the provided link: ${error}`);
  //         });
  //     }
  //   }
  // }, []);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      console.log(auth.currentUser);
    } catch (err) {
      console.error(err);
    }
  };

  // const onSubmit: SubmitHandler<IFormInputs> = (data) => {
  // sendSignInLinkToEmail(auth, data.email, actionCodeSettings)
  //   .then(() => {
  //     setPasswordResetEmailSent(true);
  //     window.localStorage.setItem("emailForSignIn", data.email);
  //   })
  //   .catch((error) => {
  //     console.error(`Couldn't send link to email: ${error}`);
  //   });
  // };

  const onEmailPasswordRegister: SubmitHandler<IFormInputs> = (data) => {
    createUserWithEmailAndPassword(
      auth,
      data.emailRegister,
      data.passwordRegister
    )
      .then(() => {})
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode == "auth/email-already-in-use") {
          setError("emailRegister", {
            type: "validate",
            message: "Email already in use, try sign in",
          });
        }
      });
  };

  const onEmailPasswordLogin: SubmitHandler<IFormInputs> = (data) => {
    signInWithEmailAndPassword(auth, data.emailLogin, data.passwordLogin)
      .then(() => {})
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode == "auth/invalid-credential") {
          setError("emailLogin", {
            type: "validate",
            message: "",
          });
          setError("passwordLogin", {
            type: "validate",
            message:
              "Either the credentials are invalid or the user is unregistered (try sign up)",
          });
        }
      });
  };

  const handleEmailPassLoginTypeChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setEmailPassLoginType(newValue);
  };

  return (
    <>
      <div className="Login">
        <Header content={<LoginContentHeader />} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          <div
            className="signin-with-google-container"
            onClick={signInWithGoogle}
          >
            <img className="google-icon" src={icons.google} alt="Google icon" />
            <h3>Google</h3>
          </div>
          <div>Or use email and password</div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={emailPassLoginType}
              onChange={handleEmailPassLoginTypeChange}
              aria-label="basic tabs example"
            >
              <Tab label="Register" />
              <Tab label="Login" />
            </Tabs>
          </Box>

          {emailPassLoginType == EmailPassLoginType.REGISTER && (
            <form
              className={"email-pass-signin"}
              onSubmit={handleSubmit(onEmailPasswordRegister)}
            >
              <TextField
                style={{
                  width: "100%",
                }}
                label="Email"
                {...register("emailRegister", {
                  required: "Please enter the email",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Please enter a valid email",
                  },
                })}
                error={!!errors.emailRegister}
                helperText={
                  errors.emailRegister
                    ? errors.emailRegister.message?.toString()
                    : ""
                }
              />
              <TextField
                style={{
                  width: "100%",
                }}
                type="password"
                label="Password"
                {...register("passwordRegister", {
                  required: "Please enter your password",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
                error={!!errors.passwordRegister}
                helperText={
                  errors.passwordRegister ? errors.passwordRegister.message : ""
                }
              />
              <button className="email-pass-btn" type="submit">
                Register
              </button>
            </form>
          )}
          {emailPassLoginType == EmailPassLoginType.LOGIN && (
            <>
              <form
                className={"email-pass-signin"}
                onSubmit={handleSubmit(onEmailPasswordLogin)}
              >
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Email"
                  {...register("emailLogin", {
                    required: "Please enter the email",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  error={!!errors.emailLogin}
                  helperText={
                    errors.emailLogin
                      ? errors.emailLogin.message?.toString()
                      : ""
                  }
                />
                <TextField
                  style={{
                    width: "100%",
                  }}
                  type="password"
                  label="Password"
                  {...register("passwordLogin", {
                    required: "Please enter your password",
                  })}
                  error={!!errors.passwordLogin}
                  helperText={
                    errors.passwordLogin ? errors.passwordLogin.message : ""
                  }
                />

                <button
                  className="email-pass-reset-pass"
                  type="submit"
                  onClick={() => navigate("/reset-password")}
                >
                  Reset password
                </button>

                <button className="email-pass-btn" type="submit">
                  Login
                </button>
              </form>
            </>
          )}

          {/* <div className="Login-other">
          <div>Or</div>
          <form
            className={"Login-other-email"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              label="Email"
              style={{ width: "90vw" }}
              {...register("email", {
                required: "Please enter the email",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Please enter a valid email",
                },
              })}
              error={!!errors.email}
              helperText={
                errors.email ? errors.email.message?.toString() : ""
              }
            />
            <button type="submit">Get link to login</button>
          </form>
        </div> */}
        </Box>
      </div>
    </>
  );
}

export default Login;
