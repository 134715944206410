import { useEffect, useState } from "react";
import { auth } from "../../configs/firebase";
import { Box, CardMedia } from "@mui/material";
import { icons, images } from "../../assets";
import HamburgerMenu from "../../components/HamburgerMenu/HamburgerMenu";
import env from "react-dotenv";
import { useParams } from "react-router-dom";

const sendAuthTokenToMicrofrontend = async () => {
  const token = await auth.currentUser?.getIdToken();
  const iframe = document.getElementById(
    "microfrontend-iframe"
  ) as HTMLIFrameElement;

  if (iframe && iframe.contentWindow && token) {
    iframe.contentWindow.postMessage({ type: "auth-token", token }, "*");
    console.log("Event sent to CV builder");
  }
};

export default function CVBuilder() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      setIsAuthenticated(true);
      sendAuthTokenToMicrofrontend();
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        sendAuthTokenToMicrofrontend();
      } else {
        setIsAuthenticated(false);
      }
    });

    // Set up an interval to refresh the token periodically
    const tokenRefreshInterval = setInterval(async () => {
      if (auth.currentUser) {
        await auth.currentUser.getIdToken(true); // Force refresh the token
        sendAuthTokenToMicrofrontend();
      }
    }, 30 * 60 * 1000); // 60 minutes

    return () => {
      unsubscribe();
      clearInterval(tokenRefreshInterval);
    };
  }, []);
  const { username, slug } = useParams();
  const iframeSrc = `${env.CV_BUILDER_URL}/${username}/${slug}`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      {/* Top nav  */}
      {isAuthenticated && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",
            // backgroundColor: "#eff6ff",
            backgroundColor: "#fff", // if logo is aspireworks
            borderBottom: "1px solid #ccc",
            height: "20px",
          }}
        >
          <HamburgerMenu color="black" alignSelf={false} />
          <CardMedia
            component="img"
            alt="CV Builder"
            // image={images.sakshamLogo}
            image={icons.headerLogo}
            sx={{
              objectFit: "contain",
              width: "200px",
              height: "20px",
            }}
          />
        </Box>
      )}
      {/* cv builder  */}
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          top: "0",
          width: "100%",
        }}
      >
        <iframe
          id="microfrontend-iframe"
          allow="clipboard-read; clipboard-write"
          src={iframeSrc}
          title="CV Builder"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
          }}
          onLoad={sendAuthTokenToMicrofrontend}
        ></iframe>
      </Box>
    </Box>
  );
}
